import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { segmentAction, useSegmentTrackLoad } from "../hooks/useSegmentTrack"

import useSignUpContext from "../hooks/useSignUpContext"
import { usePricingPlansContext } from "../context/pricing"

import { Section, Content } from "../core/LayoutComponents"
import Seo from "../core/Seo"
import ToggleSlider from "../core/Toggle"
import SignUp from "../components/SignUp"

import Pricing from "../pricing/NewPage"
import PricingCards from "../pricing/PricingCards"
import MiniPricingCards from "../pricing/MiniPricingCards"
import PricingHeader from "../pricing/PricingHeader"

const SignUpPage = () => {
  const {
    plans,
    isAnnual,
    setIsAnnual,
    selectedPlan,
    selectedPlanColor,
    selectedPlanInactiveColor,
  } = usePricingPlansContext()
  const {
    email,
    setEmail,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    businessType,
    setBusinessType,
  } = useSignUpContext()

  // useEffect(() => {
  //   console.log(selectedPlan, isAnnual)
  // }, [selectedPlan, isAnnual])

  return (
    <>
      <Seo title="We Sparkle • Sign Up" includeZohoTracking />
      <Section style={{ width: "100%" }}>
        <Content>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "var(--content-pad)",
            }}
          >
            <h2>Plan</h2>
            <ToggleSlider
              isOn={isAnnual}
              handleToggle={() => setIsAnnual(!isAnnual)}
              highlightColor={selectedPlanColor}
              inactiveColor={selectedPlanInactiveColor}
              leftText="Monthly"
              rightText="Annual"
              justifyContent="flex-end"
              subtext="Save more with Annual Plans!"
            />
          </div>
          <MiniPricingCards />

          <h2>Your site</h2>
          <SignUp
            buttonAction="continue"
            btnText={"Continue"}
            plan={selectedPlan}
            monthly={!isAnnual}
            showLeadCapture={true}
          />
        </Content>
      </Section>
    </>
  )
}

export default SignUpPage

const BackBtn = styled.button`
  font-size: 0rem;
  font-weight: bold;
  position: absolute;
  width: 45px;
  height: 45px;
  background: #fff;
  opacity: 0.75;
  border-radius: 100%;
  left: var(--content-pad);
  top: var(--content-pad);
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
`
