import React, { forwardRef, useEffect, useRef } from "react"
import styled from "styled-components"
import {
  useStickyHeaderContext,
  usePricingPlansContext,
} from "../context/pricing"

import { StickyHeader } from "../core/StickyHeader"
import ToggleSlider from "../core/Toggle"

const PricingHeader = forwardRef(({ props }, ref) => {
  const {
    plans,
    isAnnual,
    setIsAnnual,
    selectedPlan,
    selectedPlanColor,
    selectedPlanInactiveColor,
    setSelectedPlan,
  } = usePricingPlansContext()
  const { top, activeHeader } = useStickyHeaderContext()
  return (
    <StickyHeader top={top} isSticky={activeHeader === "pricing"} ref={ref}>
      <Header>
        <h1>Pricing</h1>
        <ToggleSlider
          isOn={isAnnual}
          handleToggle={() => setIsAnnual(!isAnnual)}
          highlightColor={selectedPlanColor}
          inactiveColor={selectedPlanInactiveColor}
          leftText="Monthly"
          rightText="Annual"
        />
      </Header>
    </StickyHeader>
  )
})

// export default PricingHeader

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding: 0.5rem 1rem;
  z-index: 10;
  max-width: 65rem;
  margin: 0 auto;
  > h1 {
    line-height: 1;
    font-size: clamp(1.75rem, 5vw, 2.5rem);
  }
`
