import React, { useEffect, useRef } from "react"
import styled, { css } from "styled-components"

import { useShowHideModalContext } from "../hooks/useShowHideModalContext"

import { Content } from "../core/LayoutComponents"
import { Description, Plan, SparkleHeader } from "../homepage/PricingStyling"
import { Cost, Dollar, Subtext } from "../core/PricingComponents"

import { FREE_PLAN, ANNUAL_PLAN_SUFFIX } from "../utils/constants"
import { formatMonthlyPrice } from "../utils/pricing"
import { usePricingPlansContext } from "../context/pricing"
import { navigate } from "gatsby"

const MiniPricingCards = ({ hideSelectButtons = false }) => {
  const {
    plans,
    isAnnual,
    setIsAnnual,
    selectedPlan,
    selectedPlanColor,
    selectedPlanInactiveColor,
    setSelectedPlan,
  } = usePricingPlansContext()

  return (
    <PricingGrid>
      {plans.map((plan, i) => (
        <PricingGridItem key={i + plan.key} id={`miniGrid-${plan.key}`}>
          <PricingCard
            id={plan.key}
            color={
              plan.key === selectedPlan
                ? plan.accentColor
                : plan.inactiveAccentColor
            }
            onClick={() => setSelectedPlan(plan.key)}
          >
            <SparkleHeader color={plan.accentColor}>{plan.name}</SparkleHeader>

            <Cost>
              <Dollar>
                $
                {isAnnual
                  ? formatMonthlyPrice(plan.price.annual, isAnnual)
                  : formatMonthlyPrice(plan.price.monthly)}
              </Dollar>
              <Subtext>
                {plan.key === FREE_PLAN ? "forever!" : "per month"}
              </Subtext>
            </Cost>
          </PricingCard>
        </PricingGridItem>
      ))}
    </PricingGrid>
  )
}

export default MiniPricingCards

const PricingGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: clamp(0.25rem, 0.5rem, 1rem);
  justify-self: space-around;
  margin-bottom: 1rem;
`

const PricingGridItem = styled.div``

const PricingCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: ${(props) => `2px solid ${props.color}`};
  border-radius: 0.75rem;
  box-shadow: 0 7px 10px rgba(0, 0, 0, 0.2);
  padding: 0.5rem;
  & div {
    /* background-color: var(--sparkle-darkblue); */
    color: var(--sparkle-white);
  }
  :hover {
    // transform slowly
    transition: transform 0.25s;
    transform: translateY(-2.5px);
  }
`
const CardContent = styled.div``

const CardHeader = styled.div``

const Highlight = styled.span``
